<template>
  <div
    class="
      d-flex
      justify-content-center
      align-items-center
      flex-column
      vh-100
      pb-5
    "
  >
    <h1 class="align-self-center">404</h1>
    <h3 class="align-self-center">Page Not Found</h3>
    <div class="align-self-center mt-4">
      <router-link to="/" class="btn btn-primary"
        >Return to Homepage</router-link
      >
    </div>
  </div>
</template>
